import React from 'react'
import PropTypes from 'prop-types'
import { Library } from '@styled-icons/fluentui-system-filled/Library'
import { ShieldStar } from '@styled-icons/remix-line/ShieldStar'
import { getEndpointUrl, getPageUrl } from 'utils/url'
import { LabelTypes } from 'constants/index'
import SearchInput from './SearchInput'
import NotificationPopover from './NotificationPopover'
import AccountMenu from './AccountMenu'
import NavItem from './NavItem'
import BaseNavBar from './BaseNavBar'
import { CenterAlignedFlex, Gap, SmallText } from './styles'

const currentPage = (pageName) =>
  window.location.pathname.startsWith(getPageUrl(pageName))

const renderOrgMenuItem = (org) => {
  if (org.system_client_vendor) {
    return (
      <>
        {org.name}
        <Library size={16} />
      </>
    )
  }
  if (org.system_client) {
    return (
      <>
        {org.name_with_client}
        <ShieldStar size={16} />
      </>
    )
  }
  return org.name_with_client
}

const NavBar = ({
  logoLink,
  logoTitle,
  siteLogo,
  defaultSiteLogo,
  isLoggedIn,
  isImpersonating,
  isSystemClient,
  isClient,
  isVendor,
  isLibraryVendor,
  // isNoOrg,
  autoAssessEnabled,
  currentController,
  authorizations,
  currentUser,
  currentOrgId,
  recentOrgs,
  hasCME,
  helpUrl,
  loginUrl,
  unopenedNotificationCount,
}) => {
  const currentOrg =
    recentOrgs && currentOrgId
      ? recentOrgs.find((e) => e.id === currentOrgId)
      : undefined
  const otherOrgs = recentOrgs?.filter((e) => e.id !== currentOrgId)
  return (
    <BaseNavBar
      logoLink={logoLink}
      logoTitle={logoTitle}
      siteLogo={siteLogo}
      defaultSiteLogo={defaultSiteLogo}
      isSystemClient={isSystemClient}
      isLoggedIn={isLoggedIn}
    >
      {isLoggedIn ? (
        <>
          {isClient && (
            <>
              {authorizations?.canAccessCurrentOrg && (
                <>
                  {authorizations?.canListProjects && (
                    <NavItem
                      href={getPageUrl('clientProjects')}
                      $isActive={currentController === 'client/projects'}
                      $mobileOnly
                    >
                      Projects
                    </NavItem>
                  )}
                  {authorizations?.canShowAssessmentTemplate && (
                    <NavItem
                      href={getPageUrl('clientAssessmentTemplates')}
                      $isActive={
                        currentController === 'client/assessment_templates'
                      }
                      $mobileOnly
                    >
                      Templates
                    </NavItem>
                  )}
                  <NavItem
                    href={getPageUrl('clientVendors')}
                    $isActive={currentController === 'client/vendors'}
                    $mobileOnly
                  >
                    {LabelTypes.VENDORS}
                  </NavItem>
                  {authorizations?.canListIssues && (
                    <NavItem
                      href={getPageUrl('clientIssues')}
                      $isActive={currentController === 'client/issues'}
                      $mobileOnly
                    >
                      Issues
                    </NavItem>
                  )}
                  {autoAssessEnabled &&
                    authorizations?.canManageAssistCurrentOrg && (
                      <NavItem
                        href={getPageUrl('assistAssessments')}
                        $isActive={currentController.startsWith('assist/')}
                        $mobileOnly
                      >
                        AutoAssess
                      </NavItem>
                    )}
                </>
              )}
              {!authorizations?.canAccessCurrentOrg &&
                authorizations?.canLimitedAccessCurrentOrg && (
                  <NavItem
                    href={getPageUrl('clientAssessmentsLegacy')}
                    $isActive={currentController === 'client/assessments'}
                    $mobileOnly
                  >
                    Assessments
                  </NavItem>
                )}
            </>
          )}
          {isVendor && (
            <>
              {isLibraryVendor && (
                <NavItem
                  href={getPageUrl('libraryManageProfile')}
                  $isActive={currentController === 'library/manage/profiles'}
                  $mobileOnly
                >
                  Profile
                </NavItem>
              )}
              <NavItem
                href={getPageUrl('vendorAssessments')}
                $isActive={currentController === 'vendor/assessments'}
                $mobileOnly
              >
                Assessments
              </NavItem>
              <NavItem
                href={getPageUrl('vendorIssues')}
                $isActive={currentController === 'vendor/issues'}
                $mobileOnly
              >
                Issues
              </NavItem>
            </>
          )}
          {/* still might end up supporting this */}
          {/* {isNoOrg && (
            <NavItem
              href={getPageUrl('libraryShares')}
              $isActive={currentController === 'lbraryl/shares'}
            >
              Vendor Library
            </NavItem>
          )} */}
          <Gap />
          {authorizations.canAccessCurrentOrg && (
            <SearchInput
              url={
                isVendor
                  ? getEndpointUrl('vendorSearchTerms')
                  : getEndpointUrl('clientSearchTerms')
              }
            />
          )}
          <AccountMenu currentUser={currentUser}>
            {authorizations.canAdmin && (
              <AccountMenu.Item href={getPageUrl('adminRoot')}>
                Admin
              </AccountMenu.Item>
            )}
            {authorizations.canManageAssist && (
              <AccountMenu.Item href={getPageUrl('assistQuestionSets')}>
                AutoAssess Manager
              </AccountMenu.Item>
            )}
            {hasCME && authorizations.canManageCurrentOrg && (
              <>
                <AccountMenu.Item href={getPageUrl('cmeDashboard')}>
                  Security Sense Vendor
                </AccountMenu.Item>
                <AccountMenu.Item href={getPageUrl('cmeDashboardVendors')}>
                  Security Sense Client
                </AccountMenu.Item>
              </>
            )}
            {(authorizations.canAdmin ||
              authorizations.canManageAssist ||
              (hasCME && authorizations.canManageCurrentOrg)) && (
              <AccountMenu.Divider />
            )}
            <AccountMenu.Item href={getPageUrl('user')}>
              Edit User Settings
            </AccountMenu.Item>
            <AccountMenu.Item href={getPageUrl('userRegistrationEdit')}>
              Change Password
            </AccountMenu.Item>
            {authorizations.canManageCurrentOrg && (
              <AccountMenu.Item href={getPageUrl('organization')}>
                Manage Organization
              </AccountMenu.Item>
            )}
            {!authorizations.canManageCurrentOrg &&
              authorizations.canShowCurrentOrg && (
                <AccountMenu.Item href={getPageUrl('organization')}>
                  View Organization Details
                </AccountMenu.Item>
              )}
            <AccountMenu.Item href={getPageUrl('announcements')}>
              What&apos;s new
            </AccountMenu.Item>
            <AccountMenu.Item href={helpUrl} target="_blank">
              Help
            </AccountMenu.Item>
            {currentUser.organizations_count > 1 && (
              <>
                <AccountMenu.Divider />
                <SmallText>Switch Account</SmallText>
                {currentOrg && (
                  <AccountMenu.Item
                    href={getPageUrl('userSwitchOrg', {
                      id: currentOrg.id,
                    })}
                    active
                  >
                    <CenterAlignedFlex>
                      <i className="fa fa-caret-right no-lang" />
                      {renderOrgMenuItem(currentOrg)}
                    </CenterAlignedFlex>
                  </AccountMenu.Item>
                )}
                {otherOrgs?.map((e) => (
                  <AccountMenu.Item
                    key={e.id}
                    href={getPageUrl('userSwitchOrg', { id: e.id })}
                    className="no-lang"
                  >
                    <CenterAlignedFlex>
                      {renderOrgMenuItem(e)}
                    </CenterAlignedFlex>
                  </AccountMenu.Item>
                ))}
                <AccountMenu.Item href={getPageUrl('organizationChoose')}>
                  <CenterAlignedFlex>
                    <i className="fa fa-th-list" />
                    View All
                  </CenterAlignedFlex>
                </AccountMenu.Item>
              </>
            )}
            <AccountMenu.Divider />
            <AccountMenu.Item href={getPageUrl('userSessionDestroy')}>
              Sign Out
            </AccountMenu.Item>
          </AccountMenu>
          <NotificationPopover
            userId={currentUser.id}
            unopenedNotificationCount={unopenedNotificationCount}
          />
          {isImpersonating && (
            <NavItem
              href={getPageUrl('userStopImpersonating')}
              title="Return to my user"
            >
              <i className="fa fa-lg fa-user-times" />
            </NavItem>
          )}
        </>
      ) : (
        <>
          <Gap />
          <NavItem href={loginUrl} $isActive={currentPage('userSessionNew')}>
            Login
          </NavItem>
        </>
      )}
    </BaseNavBar>
  )
}

NavBar.propTypes = {
  logoLink: PropTypes.string,
  logoTitle: PropTypes.string,
  siteLogo: PropTypes.string,
  defaultSiteLogo: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isImpersonating: PropTypes.bool,
  isSystemClient: PropTypes.bool,
  isClient: PropTypes.bool,
  isVendor: PropTypes.bool,
  isLibraryVendor: PropTypes.bool,
  // isNoOrg: PropTypes.bool,
  autoAssessEnabled: PropTypes.bool,
  currentController: PropTypes.string.isRequired,
  authorizations: PropTypes.shape({
    canAccessCurrentOrg: PropTypes.bool,
    canLimitedAccessCurrentOrg: PropTypes.bool,
    canManageCurrentOrg: PropTypes.bool,
    canManageAssistCurrentOrg: PropTypes.bool,
    canShowCurrentOrg: PropTypes.bool,
    canListProjects: PropTypes.bool,
    canListIssues: PropTypes.bool,
    canShowAssessmentTemplate: PropTypes.bool,
    canAdmin: PropTypes.bool,
    canManageAssist: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    avatar_url: PropTypes.string,
    name: PropTypes.string.isRequired,
    organizations_count: PropTypes.number.isRequired,
  }),
  currentOrgId: PropTypes.string,
  recentOrgs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      name_with_client: PropTypes.string.isRequired,
      system_client_vendor: PropTypes.bool,
      system_client: PropTypes.bool,
    }),
  ),
  hasCME: PropTypes.bool,
  helpUrl: PropTypes.string.isRequired,
  loginUrl: PropTypes.string.isRequired,
  unopenedNotificationCount: PropTypes.number,
}

export default NavBar

import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import AutoSizeTextarea from 'components-v2/atoms/AutoSizeTextarea'

const Text = ({ value: valueProp, disabled, onChange, ...rest }) => {
  const [value, setValue] = useState(valueProp)

  useEffect(() => {
    setValue(valueProp)
  }, [valueProp])

  const handleChange = useCallback(
    (e) => {
      if (disabled) {
        return
      }
      setValue([e.target.value])
    },
    [disabled],
  )
  const handleBlur = useCallback(
    (e) => {
      if (disabled) {
        return
      }
      const newValue = [e.target.value]
      setValue(newValue)
      if (onChange) {
        onChange(newValue)
      }
    },
    [disabled, onChange],
  )
  return (
    <AutoSizeTextarea
      {...rest}
      readOnly={disabled}
      value={(value && value[0]) ?? ''}
      onChange={handleChange}
      onBlur={handleBlur}
      data-test-id="answer-text"
    />
  )
}

Text.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
}

Text.defaultProps = {
  placeholder: 'Answer',
}

export default Text

import React from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import get from 'lodash/get'
import FormField from 'components-v2/molecules/FormField'
import FormDialog from 'components-v2/organisms/FormDialog'
import VendorRiskTierFormField from 'components/client/shared/VendorRiskTierFormField'
import ContributorsFieldGroup from 'components/shared/ContributorsFieldGroup'
import { LabelTypes } from 'constants/index'
import { contributorSchema } from 'schemas'
import VendorNameDomainFieldGroup from './VendorNameDomainFieldGroup'
import { CalculateButton } from './styles'

const validationSchema = yup.object().shape({
  name: yup.string().label(`${LabelTypes.VENDOR} name`).required(),
  users: yup.array().of(contributorSchema),
})

const emptyContributor = { name: undefined, email: undefined }

const CreateVendorModal = ({
  name,
  domain,
  showGloborg,
  domainReadOnly,
  suggestedContribs,
  riskTier,
  riskTierAnswers,
  onSubmit,
  ...rest
}) => {
  const [disabled, setDisabled] = React.useState(false)
  const formConfig = React.useMemo(
    () => ({
      initialValues: {
        name,
        domain,
        users:
          suggestedContribs && suggestedContribs.length
            ? suggestedContribs
            : [emptyContributor],
        tier: { riskTier, riskTierAnswers },
      },
      validationSchema,
    }),
    [name, domain, suggestedContribs, riskTier, riskTierAnswers],
  )

  const handleSubmit = React.useCallback(
    ({ tier, ...other }, actions) =>
      onSubmit(
        {
          ...other,
          risk_tier: get(tier, 'riskTier', null),
          vendor_risk_tier_calculator_id: get(tier, 'calculatorId', null),
          vendor_risk_tier_answers: get(tier, 'riskTierAnswers', null),
        },
        actions,
      ),
    [onSubmit],
  )

  return (
    <FormDialog
      {...rest}
      title={`Create a new ${LabelTypes.VENDOR.toLowerCase()}`}
      submitButtonLabel={`Create ${LabelTypes.VENDOR}`}
      disableSubmit={disabled}
      formConfig={formConfig}
      onSubmit={handleSubmit}
    >
      {showGloborg && domainReadOnly && (
        <>
          <FormField
            name="name"
            label={`${LabelTypes.VENDOR} name`}
            horizontal
            controlProps={{ autoFocus: true }}
            data-test-id="vendor-name"
          />
          <FormField name="domain" label="Domain" horizontal disabled />
        </>
      )}
      {showGloborg && !domainReadOnly && (
        <VendorNameDomainFieldGroup
          label={`${LabelTypes.VENDOR} name`}
          horizontal
          onSearching={setDisabled}
        />
      )}
      {!showGloborg && (
        <FormField
          name="name"
          label={`${LabelTypes.VENDOR} name`}
          horizontal
          controlProps={{ autoFocus: true }}
        />
      )}
      <ContributorsFieldGroup name="users" label="Contributors" horizontal />
      <FormField
        name="tier"
        label="Tier"
        horizontal
        component={VendorRiskTierFormField}
        calculateButton={CalculateButton}
      />
    </FormDialog>
  )
}

CreateVendorModal.propTypes = {
  name: PropTypes.string,
  domain: PropTypes.string,
  showGloborg: PropTypes.bool,
  domainReadOnly: PropTypes.bool,
  suggestedContribs: PropTypes.array,
  riskTier: PropTypes.number,
  riskTierAnswers: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
}

export default CreateVendorModal

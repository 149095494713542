import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
  --color-open: #000; /* Adjust the color of the icon when the nav item is open */
  ${({ active }) => active && `color: var(--color-open);`}
`

const SideNavIcon = ({ IconComponent }) => (
  <IconWrapper>
    <IconComponent size="24" />
  </IconWrapper>
)

SideNavIcon.propTypes = {
  IconComponent: PropTypes.object.isRequired,
}

export default SideNavIcon

import NavItem from 'components/layout/navbar/NavItem/'
import {
  Content,
  IconButtonBadge,
  StyledPopover,
  NotificationContainer,
  Container,
  NotificationIcon,
} from 'components/layout/navbar/NotificationPopover/styles'
import { Header } from 'components/organization/sso/styles'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { getPageUrl } from 'utils/url'
import $ from 'jquery'
import Popover from '../Popover'

function LazyNotificationsContent() {
  useEffect(() => {
    const path = encodeURIComponent(
      window.location.pathname + window.location.search,
    )
    $.ajax({
      url: `${getPageUrl('usersLazyNotifications')}?redirect=${path}`,
      type: 'GET',
      dataType: 'script',
    })
  }, [])

  return (
    <Container id="lazy_notifications_content">
      <Header>
        <span>Notifications</span>
      </Header>
      <Content>
        <i className="fa fa-spinner fa-spin fa-3x" />
      </Content>
    </Container>
  )
}

export default function NotificationBadge({ unopenedNotificationCount }) {
  return (
    <StyledPopover placement="bottom-end" offset={0}>
      <Popover.Button as={NavItem}>
        <Popover.Overlay as={NotificationContainer}>
          <NotificationIcon
            className="fa fa-bell fa-stack-1x fa-lg"
            $unopenedNotificationCount={unopenedNotificationCount}
          />
          {unopenedNotificationCount > 0 && (
            <IconButtonBadge
              $unopenedNotificationCount={unopenedNotificationCount}
            >
              {unopenedNotificationCount > 999
                ? '999+'
                : unopenedNotificationCount}
            </IconButtonBadge>
          )}
        </Popover.Overlay>
      </Popover.Button>
      <Popover.Overlay />
      <Popover.Panel>
        <LazyNotificationsContent />
      </Popover.Panel>
    </StyledPopover>
  )
}

NotificationBadge.propTypes = {
  unopenedNotificationCount: PropTypes.number,
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import map from 'lodash/map'
import includes from 'lodash/includes'
import styled from 'styled-components'
import Radio from 'components-v2/atoms/Radio'
import RatingSelect from 'components-v2/molecules/RatingSelect'
import A from 'components-v2/atoms/A'
import { hidePrint } from 'styles/mixins'

const Row = styled.div`
  margin: 8px 0;
  display: flex;
  align-items: flex-start;
`

const StyledRatingSelect = styled(RatingSelect).attrs({ markerHeight: 16 })`
  flex: none;
  width: 90px;
  margin-top: 2px;
  margin-right: 10px;
`

const ClearButton = styled(A)`
  ${hidePrint}
`

const Wrapper = styled.div``

const SingleSelect = ({
  choices,
  value,
  onChange,
  disabled,
  showAutoScores,
  autoScoreOptions,
  onAutoScoreChange,
  translatable,
  ...rest
}) => {
  const [selected, setSelected] = useState(value)

  useEffect(() => {
    setSelected(value)
  }, [value])

  const handleChange = (e) => {
    if (onChange) {
      onChange([e.target.value])
    }
  }
  const handleClear = () => {
    if (onChange) {
      onChange([])
    }
  }
  const choiceText = (choice) =>
    disabled && choice.require_artifacts ? `${choice.text} *` : choice.text
  return (
    <Wrapper {...rest}>
      {!disabled && <ClearButton onClick={handleClear}>Clear</ClearButton>}
      {map(choices, (choice) => (
        <Row key={choice.id} className={!translatable && 'no-lang'}>
          {showAutoScores && (
            <StyledRatingSelect
              value={choice.auto_score}
              markers={autoScoreOptions}
              labelVisible={false}
              onChange={(score) => onAutoScoreChange(choice.id, score)}
            />
          )}
          <Radio
            label={choiceText(choice, disabled)}
            value={choice.id}
            checked={includes(selected, choice.id)}
            disabled={disabled}
            onChange={handleChange}
            data-test-id="single-select-choice"
          />
        </Row>
      ))}
    </Wrapper>
  )
}

SingleSelect.propTypes = {
  choices: PropTypes.array,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showAutoScores: PropTypes.bool,
  autoScoreOptions: PropTypes.array,
  onAutoScoreChange: PropTypes.func,
  translatable: PropTypes.bool,
}

const doNothing = () => {}

SingleSelect.defaultProps = {
  choices: [],
  value: [],
  onChange: undefined,
  onAutoScoreChange: doNothing,
  translatable: true,
}

export default SingleSelect

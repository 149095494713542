import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Select from 'react-select'
import Async from 'react-select/async'
import Creatable from 'react-select/creatable'

const style = css`
  ${({ size }) =>
    ['sm', 'small'].includes(size) &&
    `
    font-size: 14px;

    .Select__control {
      min-height: 30px;
    }

    .Select__value-container {
      padding-top: 0;
      padding-bottom: 0;
    }

    .Select__indicator {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  `}

  ${({ error }) =>
    error &&
    `
    .Select__control {
      border-color: #a94442;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);

      &--is-focused {
        border-color: #983d3b;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c16361;
      }
    }
  `}
`

const StyledSelect = styled(Select)`
  ${style}
`
const StyledAsyncSelect = styled(Async)`
  ${style}
`

// Make value prop simple
const SearchableSelect = React.forwardRef(
  (
    {
      isAsync,
      isSimpleValue,
      value,
      options,
      isMulti,
      getOptionValue,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const Component = isAsync ? StyledAsyncSelect : StyledSelect
    const newValue = isSimpleValue
      ? isMulti
        ? options.filter((e) => value?.includes(getOptionValue(e)))
        : options.find((e) => getOptionValue(e) === value)
      : value
    const handleChange = (v, actionMeta) => {
      const newV = isMulti
        ? v?.map((e) => getOptionValue(e))
        : v
        ? getOptionValue(v)
        : v
      return onChange(newV, actionMeta)
    }
    return (
      <Component
        ref={ref}
        classNamePrefix="Select"
        {...rest}
        isMulti={isMulti}
        value={newValue}
        options={options}
        getOptionValue={getOptionValue}
        onChange={isSimpleValue ? handleChange : onChange}
        data-test-id="searchable-select"
      />
    )
  },
)

SearchableSelect.propTypes = {
  isAsync: PropTypes.bool,
  isSimpleValue: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf(PropTypes.any)]),
  isMulti: PropTypes.bool,
  getOptionValue: PropTypes.func,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

SearchableSelect.defaultProps = {
  options: [],
  isSimpleValue: true,
  getOptionValue: (e) => e.value,
}

export default SearchableSelect

const StyledCreatable = styled(Creatable)`
  ${style}
`

// Make value prop simple
const CreatableSelect = React.forwardRef(
  ({ isSimpleValue, value, options, isMulti, onChange, ...rest }, ref) => {
    const newValue = isSimpleValue
      ? isMulti
        ? value?.map(
            (v) => options.find((e) => e.value === v) ?? { value: v, label: v },
          )
        : value !== undefined && value !== null
        ? options.find((e) => e.value === value) ?? { value, label: value }
        : undefined
      : value
    const handleChange = (v, actionMeta) => {
      const newV = isMulti ? v?.map((e) => e.value) : v ? v.value : v
      return onChange(newV, actionMeta)
    }
    return (
      <StyledCreatable
        ref={ref}
        classNamePrefix="Select"
        {...rest}
        isMulti={isMulti}
        value={newValue}
        options={options}
        onChange={isSimpleValue ? handleChange : onChange}
      />
    )
  },
)
CreatableSelect.propTypes = {
  isSimpleValue: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf(PropTypes.any)]),
  isMulti: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

CreatableSelect.defaultProps = {
  isSimpleValue: true,
  options: [],
}

export { CreatableSelect }

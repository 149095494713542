import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SideNavText from 'components-v2/atoms/SideNavText'
import SideNavIcon from 'components-v2/atoms/SideNavIcon'
import Clickable from 'components-v2/atoms/Clickable'

const NavItemWrapper = styled(Clickable)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 10px 10px;
  color: #aaa;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  ${({ $isActive }) =>
    $isActive &&
    `
    color: #000;
  `}
`

const NavItem = ({ icon: IconComponent, label, href, isActive }) => (
  <NavItemWrapper active={isActive} href={href}>
    <SideNavIcon IconComponent={IconComponent} />
    <SideNavText text={label} />
  </NavItemWrapper>
)

NavItem.propTypes = {
  icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
}

export default NavItem
